<template>
  <div class="mt-2">
    <v-row class="justify-start align-center">
      <v-col cols="1">
        <v-text-field v-model="days" type="number" label="Количество дней" />
      </v-col>
      <v-col cols="4">
        <v-btn class="primary" @click="calculate">Поиск</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          loading-text="Загрузка... Пожалуйста подождите"
        >
          <template v-slot:item.totalBet="{ item }">
            {{ (item.totalBet / 100).toFixed(2) }}
          </template>
          <template v-slot:item.totalWin="{ item }">
            {{ (item.totalWin / 100).toFixed(2) }}
          </template>
          <template v-slot:item.RTP="{ item }">
            {{ (item.RTP || 0).toFixed(2) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import adminStatisticService from "@/services/admin.statistic.service";
export default {
  name: "Games",
  data: () => ({
    loading: false,
    headers: [
      { text: "Игра", value: "gameId" },
      { text: "Всего ставок", value: "totalBet" },
      { text: "Всего выигрышей", value: "totalWin" },
      { text: "Процент отдачи", value: "RTP" }
    ],
    items: [],
    days: 7
  }),
  methods: {
    calculate() {
      this.loading = true;
      adminStatisticService
        .getStatisticGames({
          days: parseInt(this.days)
        })
        .then(data => {
          console.log(data);
          this.items = data;
        })
        .catch(e => {
          this.$noty.error(e.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped></style>
