import { api } from "@/instances/http";

class AdminStatisticService {
  getStatisticGames(params = {}) {
    return api
      .post("/admin/statistic/getStatisticGames", { ...params })
      .then(response => {
        return response.data;
      });
  }
}

export default new AdminStatisticService();
