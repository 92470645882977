<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>Статистика</v-card-title>
        <v-card-text>
          <v-tabs v-model="tab">
            <v-tab>Игры</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <Games />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Games from "@/views/pages/Statistic/Games";
export default {
  data: () => ({
    tab: 0
  }),
  components: {
    Games
  },
  name: "Index"
};
</script>

<style scoped></style>
